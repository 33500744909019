import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
  },
  watch: {
    isAuthenticated () {
      // Refresh
      this.$router.go()
    },
  },
  async mounted() {
    if (!this.isAuthenticated && process.client) {
      this.$nuxt.error({ statusCode: 403 });
      if (process.client) {
        this.showModal({componentName:'UserModal'})
      }
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'modal/showModal',
    })
  }
}
