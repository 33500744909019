
  export default {
    data() {
      return {
        aliasAsData: this.alias,
        crumbs: null
      }
    },
    props: [
      'alias',
      'titles',
      'custom'
    ],
    mounted() {
      if(process.client) {
        if (this.custom) {
          this.crumbs = this.custom
        } else {
          let currentURL = this.$route.path.replace(/\/$/, "")
          let currentURLArray = currentURL.split('/')

          if (this.aliasAsData !== false) {
            currentURLArray = ["", this.aliasAsData, this.titles[1]]
            this.aliasAsData = false
          }

          currentURLArray.shift()
          let breadcrumbsObject = []
          let breadcrumbPath = "/"

          for (let breadcrumbItem in currentURLArray) {
            if (currentURLArray.length - 1 === breadcrumbItem) {
              breadcrumbsObject.push({
                'to': null
                }
              )
            } else {
              breadcrumbPath = breadcrumbPath + currentURLArray[breadcrumbItem] + '/'
              breadcrumbsObject.push({
                  'to': breadcrumbPath
                }
              )
            }
          }
          this.crumbs = breadcrumbsObject;
        }
      }
    }
  };
