
  export default {
    name: "Form",
    props: [
      'id',
      'form',
      'fields'
    ],
    data () {
      return {
        file: null
      }
    },
    mounted () {
      if (process.client) {
        this.$store.dispatch('form/recaptchaInit')
        let contactRadios = document.getElementById('contact-radios')
        if(typeof (contactRadios) !== 'undefined' && contactRadios !== null) {

          contactRadios.value = ([this.$store.state.radio.radioNationalTid, null].includes(this.$store.state.radio.radioTid)) 
            ? '' : this.$store.state.radio.radioCode
            
        }

        let radio = document.querySelectorAll('#partners #contact-radios')

        if(typeof (radio) !== 'undefined' && typeof (radio[0]) !== 'undefined') {
          if (typeof (radio[0].closest('.is-flex').parentNode) !== 'undefined') {
            radio[0].closest('.is-flex').parentNode.style.display = 'none'
          }
        }

        let currentFilledMandatory = []

        let mandatoryFieldsCount = document.querySelectorAll("#"+this.id+" [required]").length
        let mandatoryFields = document.querySelectorAll("#"+this.id+" [required]")
        let submitButton = document.querySelectorAll("#"+this.id+" #submitBtn")[0]
        let form = document.getElementsByClassName(this.id)[0]

        mandatoryFields.forEach(function(mandatoryField){
          mandatoryField.addEventListener('input', function()
          {

            if(!currentFilledMandatory.includes(mandatoryField.getAttribute('name'))){
              currentFilledMandatory.push(mandatoryField.getAttribute('name'))
              if(mandatoryField.getAttribute('type') === "checkbox"){
                mandatoryField.setAttribute('checked', '')
              }
            }
            else if(mandatoryField.value === '' && currentFilledMandatory.includes(mandatoryField.getAttribute('name'))){
              currentFilledMandatory = currentFilledMandatory.filter(item => item !== mandatoryField.getAttribute('name'))
            }
            else if (mandatoryField.getAttribute('checked') !== null && currentFilledMandatory.includes(mandatoryField.getAttribute('name'))){
              mandatoryField.removeAttribute('checked')
              currentFilledMandatory = currentFilledMandatory.filter(item => item !== mandatoryField.getAttribute('name'))
            }

            if(currentFilledMandatory.length === mandatoryFieldsCount){
              if(submitButton.getAttribute('disabled')!== null){
                submitButton.removeAttribute('disabled')
                form.onsubmit = function () {
                  submitButton.setAttribute('disabled', '')
                }
              }
            }
            else {
              submitButton.setAttribute('disabled', '')
            }
          });
        })
      }
    },
    methods: {
      orderNewsletterRadio(radios) {
        const propertyValues = Object.values(radios)
        let temp = []
        let natio = null

        // formate le tableau de radios
        propertyValues.forEach((radio) => {
          if (radio.includes('nationale')) {
            natio = [radio.split("---")[0], radio.split("---")[1]]
          } else {
              if (!(radio.includes('Sélectionner') || radio.includes('Select') || radio.includes('None') || radio.includes('Aucun'))) { // item Select ou Sélectionner (selon langue)
                temp.push([radio.split("---")[0], radio.split("---")[1]])
              }
          }
        })

        temp.sort( function(a , b){
          if(a[0] > b[0]) return 1
          if(a[0] < b[0]) return -1
          return 0
        });

        // Ajout Newsletter Nationale au début du tableau
        temp.unshift(natio)

        return temp
      },
      order(radios) {
        let orderedRadio = []
        let selectFirstItem
        for (let radio in radios) {
            orderedRadio.push([radio, radios[radio]])
        }

        orderedRadio.sort(this.compare)

        for (let i=0; i<orderedRadio.length; i++) {
          if (orderedRadio[i][1] === "Choisir dans la liste") {
            selectFirstItem = orderedRadio[i]
            orderedRadio.splice(i, 1)
          }
        }
        orderedRadio.unshift(selectFirstItem)

      return orderedRadio

      },
      compare(a,b) {
        if (a[1] < b[1])
          return -1;
        if (a[1] > b[1])
          return 1;
        return 0;
      },
      closeNotification() {
        // Notification handler
        let deleteBtn = document.querySelectorAll('#'+this.id+' #notification .close')[0]
        let notification = document.querySelectorAll('#'+this.id+' #notification')[0]

        //notification.classList.add("is-hidden")
        if(typeof (deleteBtn) !== "undefined") {
          this.$store.commit('form/setMessage', null)
          notification.classList.replace("flex","hidden")
        }
      },
      tableSelect(event) {
        let tableselect = event.target.closest('.tableselect')
        if(typeof (tableselect) !== 'undefined') {
          let checkboxes = tableselect.querySelectorAll('.checkbox')
          let button = tableselect.querySelectorAll('.button')

          checkboxes.forEach(function(checkbox) {
            checkbox.checked = !checkbox.checked
          })

          if(typeof (checkboxes[0]) !== 'undefined') {
            if(checkboxes[0].checked) {
              button[0].classList.add('is-active')
            }
            else if(!checkboxes[0].checked) {
              button[0].classList.remove('is-active')
            }
          }
        }
      },
      handleFileUpload() {
        this.file = this.$refs.file[0].files[0];
      },
      async submitItem(event) {
        event.preventDefault()
        let submitBtn = document.getElementById("submitBtn")
        submitBtn.disabled = true
         let token = await this.$recaptcha.execute('login')
         let siteverify = await this.$defaultRepository.getByFullUrl(this.$config.baseURL+'/recaptcha-siteverify/verify', {
           'token': token
         })

        siteverify.success = true
        if(typeof (siteverify.success) !== 'undefined' && siteverify.success) {
          this.$nextTick(() => {
            this.$nuxt.$loading.start()

            if(this.file !== null) {
              /*let response = this.$store.dispatch('form/submitFile', this.file)
              if(response.data.fid) {
                let fid = response.data.fid[0].value*/

                /*this.$store.dispatch(
                  'form/submit',
                  event.target
                )
              }*/
            }
            else {
              this.$store.dispatch(
                'form/submit',
                {form: event.target, webform_id: this.form ? this.form : this.id}
              )
            }
            setTimeout(() => this.$nuxt.$loading.finish(), 500)
            // let notification = document.querySelectorAll('#'+this.id+' .notification')[0]
            // notification.classList.replace("hidden","flex")
          })
        }
      }
    }
  }
